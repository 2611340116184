<template>
  <div v-if="isMaster">
    <base-item-group
      :item="items"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',

  data: () => ({
    items: {
      group: '/master',
      icon: 'fa-user-crown',
      title: 'Admin',
      children: [
        {
          title: 'Wettkampforte',
          to: 'venues',
          icon: 'mdi-home-group'
        },
        {
          title: 'Hauptevents',
          to: 'events',
          icon: 'mdi-calendar-multiple'
        },
        {
          title: 'Vereine',
          to: 'clubs',
          icon: 'mdi-account-supervisor-circle'
        },
        {
          title: 'Disziplinen',
          to: 'disciplines',
          icon: 'fa-dumbbell'
        },
        {
          title: 'Personen verwalten',
          to: 'person',
          icon: 'mdi-account-edit'
        },
        {
          title: 'Personen zusammenführen',
          to: 'merge',
          icon: 'mdi-account-switch'
        },
        {
          title: 'Turnportalabfrage',
          to: 'turnportal',
          icon: 'mdi-account-question'
        },
        {
          title: 'Serverübersicht',
          to: 'server',
          icon: 'mdi-server'
        },
        {
          title: 'Seitenstatistik',
          to: 'pagelog',
          icon: 'fa-chart-line'
        }
      ]
    }
  }),

  computed: {
    ...mapGetters(['isMaster'])
  }
}
</script>

<style scoped>

</style>
